.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--lt-black);
}

.icon {
  margin-right: 10px;
  line-height: 16px;
  font-size: 16px;
}
