@import 'src/modules/common/styles/utils/variables';

.imageContent {
    min-width: 55px;
    max-width: 104px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .imageContainer {
    background-color: #c4c4c4;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    svg {
      width: 23px;
      color: $gray-dark;
    }
  }
  .small {
    width: 55px;
    height: 55px;
    i {
      width: 23px;
      height: 26px;
    }
  }
  .large {
    width: 104px;
    height: 104px;
    i {
      width: 23px;
      height: 29px;
    }
  }
  .imageButton {
    font-weight: 600;
    color: $black;
    background-color: $white;
    border: none;
    padding: 6px 10px;
    margin-top: 10px;
    &:hover {
      color: $black;
    }
    &:focus, &:active {
      background-color: $white;
      color: $black !important;
    }
  }