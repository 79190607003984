.ico {
  margin-left: 10px;
  margin-top: -3px;
}
.container {
  display: inline-block;
  position: relative;
  & .tooltip {
    font-weight: normal;
    font-size: 11px;
    background-color: #fff;
    color: #3f3f3f;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
    b {
      font-weight: 600;
    }
  }
}
