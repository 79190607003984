.container {
  background: var(--lt-gray-3);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  font-family: var(--default-font-family, "SuisseIntl");
  color: var(--info);
  margin: 0;
  padding: 30px 30px 0;
}
