@import 'src/modules/common/styles/utils/variables';

.label {
  display: block;
  margin-top: 25px;
}

.styledInput {
  height: 35px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: var(--default-font-family, "SuisseIntl");
  color: $black;
  font-weight: 400;
  &:hover {
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  }

  &::placeholder {
    color: $legato-gray-dark;
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
  }
}
