.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.thumbnail {
  margin-bottom: 30px;
}

.message {
  font-size: 26px;
  line-height: 28.6px;
  margin-bottom: 15px;
}

.details {
  font-size: 16px;
  line-height: 18px;
}