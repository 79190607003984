.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: calc(50vh - 60px);
  padding: 10px 0;
  border: 1px solid #CBCBCB;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  margin-top: 5px;
}
.resultsList {
  height: 100%;
  max-width: 350px;
  overflow: auto;
  position: relative;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
  .item {
    font-size: 13px;
    line-height: 15px;
    margin: 0;
    list-style: none;
    padding: 10px 20px 10px 20px;
    font-weight: 400;
    border-left: 2px solid transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .addon {
    color: #646464;
  }
  .item {
    &.isActive {
      background-color: #F4F4F4;
      border-color: #000;
    }
    &:hover {
      background-color: #F4F4F4;
      border-color: #000;
      cursor: pointer;
    }
  }
}
