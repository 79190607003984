@import '../utils/variables';

.bold {
  font-weight: 600;
}

.gray {
  color: $lt-black-3;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

b, strong {
  font-weight: 700;
  font-family: inherit;
}
