// COLORS
$grow-green: #258200;
$accent-primary: #0061ab;
$accent-primary-hover: #004478;
$accent-primary-dark: #0261ab;
$accent-primary-dark-2: #094881;
$accent-link: #4a90e2;
$accent-primary-lt: #e3f1fc;
$accent-secondary: #f2f2f2;
$white: #fff;
$gray: #505050;
$lt-gray: #eeeded;
$lt-gray-2: #e5e5e5;
$lt-gray-3: #f4f4f4;
$lt-gray-4: #f9f9f9;
$md-gray: #d5d5d5;
$md-gray-2: #c1c4cc;
$md-gray-3: #d6dce3;
$drk-gray-1: #6d7278;
$gray-border: #979797;
$gray-dark: #71767D;
$light-border: #0000001a;
$black: #000;
$legato-gray: #929292;
$legato-gray-dark: #646464;
$legato-branded-primary: #0032A0;
$legato-primary-second: #002880;
$second-black: #383838;
$lt-black: #3f3f3f;
$lt-black-2: #4a4a4a;
$lt-black-3: #71767c;
$lt-black-4: #626262;
$lt-blue: #eff8ff;
$scrim-container: rgba(0, 0, 0, 0.8);
$warning-soft: #fde3e3;
$warning-solid: #e02020;
$warning-yellow: #e8be04;
$success-green: #008642;
$error-red: #C6262D;

// (pre-WMG theme)
$success-bg: #d4eeda;
$success-text: #155723;
$fail-bg: #f8d6db;
$fail-text: #721c25;
$alert-bg: #cc3333;
$timeout-bg: #f6a624;

// BOX SHADOW
$box-shadow-btn: 0 1px 10px 0 rgba(211, 211, 211, 0.5),
0 1px 2px 0 rgba(153, 153, 153, 0.5);
$box-shadow-card: 0 1px 10px 0 rgba(211, 211, 211, 0.5),
0 1px 2px 0 rgba(153, 153, 153, 0.5);
$box-shadow-modal: 1px 1px 3px rgba(0, 0, 0, 0.3);
$box-shadow-modal-2: 6px 6px 12px rgba(0, 0, 0, 0.2);

// SPACING
$spacing-unit: 5px;
$space-1: $spacing-unit * 1;
$space-2: $spacing-unit * 2;
$space-3: $spacing-unit * 3;
$space-4: $spacing-unit * 4;
$space-5: $spacing-unit * 5;
$space-10: $spacing-unit * 10;

$logo-space: 30px;

// Z-INDEX
$z-index-1: 1;
$z-index-2: 2;
$z-index-3: 3;
$z-index-4: 4;
$z-index-header: 5;

// BREAKPOINTS
$sm: 768px;
$md: 992px;
$lg: 1200px;

// CARDS
$card-border-radius: 6px;

// MODAL
$modal-light-border: #dee2e6;
$modal-border: #dee2e6;
$modal-border-2: #cbcbcb;

$modal-confirm-bg: #e3f1fc;
$modal-confirm-color: #0061ab;

$modal-success-bg: #e3fcef;
$modal-success-color: #008642;

$modal-warning-bg: #fcf3e3;
$modal-warning-color: #e8be04;

$modal-destructive-bg: #f5c6cb;
$modal-destructive-color: #d72e3d;

// ENV COLORS
$dev-orange: #FF4800;
$qa-purple: #8040E1;
$uat-blue: #2473FF;
$staging-pink: #FF4AC3;
