.container {
  position: relative;
}

.dropdownBtn {
  height: 35px;
  color: #fff;
  background: #000;
  border: 1px solid #000;
  appearance: none;
  padding: 10px 15px;
  margin: 0 20px;
  font-size: 13px;
  position: relative;
  transition: 0.3s background ease-in-out;
  line-height: 16px;

  & span {
    display: flex;
    align-items: center;
  }

  & i {
    color: #fff !important;
    padding: 0;
    margin-left: 10px;
  }

  &:hover, &:focus, &:active {
    color: #fff;
    background: #000;
  }
}

.light {
  color: #000;
  background: #fff;

  & i {
    color: #000 !important;
  }
// TODO: refactor with buttons uplift
  &:hover, &:focus, &:active {
    color: #000 !important;
    background: #fff !important;
    border: 1px solid #000 !important;
  }
}

.link {
  padding: 0 26px;
  height: 40px;
  border-left: 2px solid transparent;
  white-space: nowrap;
  text-decoration: none;
  display: block;
  line-height: 38px;
  color: var(--lt-black);
  transition: 0.3s background, color ease-in-out;

  &:hover, &:focus, {
    color: #000;
    background: #e5e3e3;
    text-decoration: none;
    border-left: 2px solid #000;
  }

  &:active {
    color: #fff;
    background: var(--accent-primary-hover);
    text-decoration: none;
  }
}

.linkContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.linkIcon {
  width: 16px;
  height: 16px;
  color: #000 !important;
  margin-right: 10px;
}

.list {
  position: absolute;
  min-width: 100%;
  background: #fff;
  z-index: 10;
  right: 20px;
  top: 40px;
  padding: 15px 0;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}
