@import '../../../../../common/styles/utils/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0;
}

.message {
  margin: 15px 70px 0;
  text-align: center;
  font-size: 13px;
  line-height: 14px;
  color: var(--info);
  &.isniFound {
    margin: 15px 40px 0;
  }
  br {
    line-height: 20px;
    margin-bottom: 20px;

  }
}

.icon {
  font-size: 26px;
  line-height: 30px;
}

.success {
  color: $success-green;
}

.warning {
  color: $warning-yellow;
}

.error {
  color: $error-red;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 10px;
}
