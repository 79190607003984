@import '../utils/variables';

// absolutely center an element in a relatively positioned element
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fixed-center {
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
}

// center rows
.centered-row {
  display: flex;
  justify-content: center;
}

// Widths
.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}
.w-70 {
  width: 70%;
}

.scrim-container {
  position: fixed;
  min-height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $z-index-1;
  background-color: $scrim-container;

  &.transparent {
    background-color: unset;
  }

  &.centered {
    align-items: center;
  }
}

.blue-background {
  background: $lt-blue !important;
}

.inline-block {
  display: inline-block;
}

:root {
  // COLORS
  --grow-green: #{$grow-green};
  --accent-primary: #{$accent-primary};
  --accent-primary-hover: #{$accent-primary-hover};
  --accent-primary-dark: #{$accent-primary-dark};
  --accent-primary-dark-2: #{$accent-primary-dark-2};
  --accent-link: #{$accent-link};
  --accent-primary-lt: #{$accent-primary-lt};
  --accent-secondary: #{$accent-secondary};
  --white: #{$white};
  --gray: #{$gray};
  --lt-gray: #{$lt-gray};
  --lt-gray-2: #{$lt-gray-2};
  --lt-gray-3: #{$lt-gray-3};
  --lt-gray-4: #{$lt-gray-4};
  --md-gray: #{$md-gray};
  --md-gray-2: #{$md-gray-2};
  --md-gray-3: #{$md-gray-3};
  --drk-gray-1: #{$drk-gray-1};
  --lt-black: #{$lt-black};
  --gray-border: #{$gray-border};
  --small-thumbnail-width: 85px;
  --small-thumbnail-height: 85px;
  --card-radius: #{$card-border-radius};
  --warning-yellow: #{$warning-yellow};

  --info: #383838;
  --success: #008642;
  --warning: #DB7B0B;
  --danger: #D72E3D;
  --primary: #0032A0;

  --modal-light-border: #{$modal-light-border};
  --modal-border: #{$modal-border};
  --modal-confirm-bg: #{$modal-confirm-bg};
  --modal-confirm-color: #{$modal-confirm-color};
  --modal-success-bg: #{$modal-success-bg};
  --modal-success-color: #{$modal-success-color};
  --modal-warning-bg: #{$modal-warning-bg};
  --modal-warning-color: #{$modal-warning-color};
  --modal-destructive-bg: #{$modal-destructive-bg};
  --modal-destructive-color: #{$modal-destructive-color};

  // SPACINGS
  --history-count-top: 128px;
  --search-results-table-top: 231px;
  --adv-search-results-table-top: 242px;
  --adv-search-empty-name-results-table-top: 208px;
}
