.container {
  background: var(--white);
  width: 230px;
  padding: 20px 18px;
  border-radius: 6px;
  border: 1px solid #CBCBCB;
}

.label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-top: 10px;
}

.field {
  display: block;
  flex: 1 0 100%;
  width: 100%;
}
.switch {
  margin-bottom: 20px;
}