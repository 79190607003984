@import '../../../styles/utils/variables';

.isni-modal {
  position: absolute;
  background: white;
  border: 1px solid $md-gray;
  margin-top: 5px;
  padding: 0 15px;
  z-index: 1;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.2);
  border-radius: 6px;

  &-from-edit {
    @extend .isni-modal;
    width: 100%;
  }

  &-search {
    width: 300px;
  }

  &-form {
    width: 93.5%;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 15px 0;

    .btn-primary {
      text-transform: none;
    }
  }

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__input {
    margin: 0 10px 0 0;

    input {
      width: 100%;
      height: 30px;
      border: 1px solid $md-gray;
      padding: 0 5px;

      &:disabled {
        background: $lt-gray;
      }
    }
  }

  &__delete-wrapper {
    position: absolute;
    left: 13px;
    bottom: 20px;
  }
}
