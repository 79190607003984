@import "src/modules/common/styles/utils/variables";

.container {
  display: flex;
  gap: 15px;
}

.icon {
}

.content {
  font-size: 16px;
  line-height: 17px;
}

.title, .subtitle {
}

.title {
  color: $legato-gray;
  font-weight: 600;
}

.subtitle {
  color: $black;
  font-weight: 600;
}

.description {
  margin-top: 9px;
  color: $legato-gray-dark;
}
