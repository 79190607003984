.container {
  composes: inputLabel from '/src/@grow-components/Form/CommonStyles.module.scss';
  margin: 0;
}

.warning {
  composes: iconInLabel from '/src/@grow-components/Form/CommonStyles.module.scss';
}

.label {
  display: inline-flex;
  align-items: center;
  margin-bottom: 5px;
  & > span {
    display: inline-block;
    margin-left: 6px;
    height: 13px;
  }
}
