.link {
  color: var(--accent-primary);
  font-weight: inherit;
  &:hover {
    color: var(--accent-link);
  }
}

.open {
  background-color: #e3f1fc;
  box-shadow: inset 2px 0 0 0 #0061ab;
  & .name {
    font-weight: bold;
  }
}
