@import 'src/modules/common/styles/utils/variables';

.userModal {
  width: 626px;
  height: 401px;
  background-color: $white;
  border-radius: $card-border-radius;
  box-shadow: $box-shadow-modal;
  border: 1px solid $modal-border-2;
  padding: 30px;
  }
  .modalHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  //title of the modal
  .modalTitle {
    display: inherit;
    align-items: center;
    h3 {
      font-family: var(--default-font-family, "SuisseIntl");
      margin: 0;
      margin-left: 10px;
    }
    i {
      height: 20px;
      width: 23px;
      left: 0px;
      top: 0px;
      border-radius: nullpx;
    }
  }
  .modalIcon{
    font-size: 20px;
    font-weight: 300 !important;
    color: #383838 !important;
  }
  
.modalContent {
  display: grid;
  grid-template-columns: 163px 1fr 208px;
  align-items: flex-start;
  .descriptionContent {
    display: flex;
    flex-direction: column;
    span {
      font-weight: 600;
    }
  }
}