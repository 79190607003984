@import 'src/modules/common/styles/utils/variables';

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 245px;
  grid-template-rows: repeat(auto-fit, 40px);
  grid-auto-flow: column;
  gap: 20px 0;
}

.item {
  font-size: 13px;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.title {
  font-weight: 600;
  margin-bottom: 10px;
}

.title,
.value {
  line-height: 15px;
  color: $second-black;
}

.link {
  color: #0032a0;
}