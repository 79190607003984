.icon {
    position: absolute;
    pointer-events: none;
    top: 0;
    right: 12px;
    bottom: 0;
    z-index: 0;
    margin: auto;
    height: 12px;
    width: 13px;
    color: #c1c4cc;
  }

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.blueIcon{
  i{
    color: #0032A0;
  }
}


