@import '../utils/variables';

// Display hamburger menu on small screens
@media (max-width: 768px) {
  .navbar__right-non-mobile {
    display: none;
  }
}
@media (min-width: 769px) {
  .navbar__right-mobile {
    display: none;
  }
}
nav {
  background-color: #fff;
  .nav__contents-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .navbar__left {
    .navbar-header {
      margin-right: 5px;
    }
    display: flex;
    align-items: center;
  }
  .navbar__right {
    i {
      color: $accent-primary;
      &:hover {
        color: $accent-link;
      }
    }
  }
  .app-link-btn {
    color: $accent-primary;
    padding: 21px;
    cursor: pointer;
  }
  .active {
    color: #3f3f3f;
    background-color: #f2f2f2;
    cursor: inherit;
  }
  .party-workspace-link {
    color: $lt-black;
  }
}

@media (max-width: 920px) {
  nav {
    .nav__contents-wrapper {
      grid-template-columns: auto auto;
      grid-template-areas:
        'logo dropdown'
        'search .';
    }
    .search-box-wrapper {
      margin-bottom: 10px;
      margin-left: $logo-space;
    }
  }
}

.header {
  position: sticky;
  top: 0;
  z-index: 11;
  .current-breadcrumb {
    border: none;
    text-transform: capitalize;
  }
  .navbar-header {
    a {
      display: flex;
      line-height: 20px;
      white-space: nowrap;
    }
  }
  .navbar__right {
    display: flex;
    align-items: center;
    padding-right: 20px;

    p {
      margin: 0;
    }
  }
  .navbar-appname {
    padding: $space-4;
    display: flex;
    align-items: center;
    .navbar-brand-intable {
      font-size: 13px;
      line-height: 16px;
      text-transform: uppercase;
      color: #3f3f3f;
      transition: color 0.2s ease-in-out;
      &:hover {
        color: $accent-link;
      }
    }
  }
  .header__dropdown {
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 2;
    margin: 0 25px 0;
    box-shadow: $box-shadow-card;
    display: flex;
    flex-direction: row;
    .left {
      margin-right: $space-2;
    }
    ul {
      list-style: none;
    }
  }

  .nav-dropdown {
    left: auto;
    top: 4.5rem;
  }

  .user-initials {
    font-size: 1.2rem;
    font-weight: 700;
    background-color: $md-gray;
    color: $lt-black;
    border: 1px solid $gray-border;
    border-radius: 50%;
    padding: $space-2;
    text-align: center;
    width: 4rem;
  }
}

.search-box-wrapper {
  margin: auto;
  max-width: 580px;
  min-width: 380px;

  .search-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    border: 1px solid $md-gray-3;
    caret-color: $accent-primary;
    &:focus-within {
      border: 1px solid $accent-primary;
    }

    i {
      border: none;
      color: $lt-black-3;
      &:hover {
        color: $accent-primary;
      }
    }
    input {
      border: none;
      color: #000;
      &::placeholder {
        font-style: italic;
        color: $drk-gray-1;
      }
      &:focus {
        outline: none;
      }
    }
    .select-type {
      border: none;
      .btn {
        border: none;
      }
      .btn-dropdown-element,
      .search-type,
      i {
        color: $accent-primary;
        transition: color 0.2s ease;
        &:hover {
          color: $accent-primary-dark-2;
        }
      }
    }

    .select-type {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 164px;
      color: $lt-black-3;
      flex-grow: 0;
      p {
        margin: 0;

        text-transform: none;
      }
    }
    .select-type__dropdown {
      position: absolute;
      padding: 0 1rem;
      top: 100%;
      right: 0;
      z-index: $z-index-2;
      box-shadow: $box-shadow-card;
      button {
        padding: 0;
      }
    }

    .input-block {
      display: flex;
      input {
        flex-grow: 1;
      }
      .buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 80px;
      }
    }
  }
  .input-focus {
    border: 1px solid $accent-primary;
  }
  .highlight {
    background-color: $accent-primary-dark-2;
    color: $white;
  }
}

.dropdown-type__option {
  display: flex;
  align-items: center;
  padding: $space-1;
  background-color: $white;
  color: $lt-black-3;
  cursor: pointer;
  &:hover {
    background-color: $accent-primary;
    color: $white;
  }
  &:nth-child(even) {
    border-top: none;
  }
  input {
    min-width: 20px;
    max-width: 20px;
    margin: 0 10px 0 0;
  }
  .dropdown-type__option_name {
    display: block;
  }
}
