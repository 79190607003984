.label {
  display: inline-block;
}
.input {
  appearance: none;
  & + .label {
    position: relative;
    padding-left: 40px;
    &::before, &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-radius: 8px;
      margin: auto;
      height: 15px;
      transition: 0.3s all ease-in-out;
    }
    &::before {
      background: #E5E5E5;
      border: 1px solid #E5E5E5;
      width: 30px;
    }
    &::after {
      background: #CBCBCB;
      width: 15px;
    }
  }
  &:checked ~ .label {
    &::before {
      background: #CBCBCB;
    }
    &::after {
      background: #646464;
      transform: translate3d(15px, 0, 0);
    }
  }
  &:focus + .label::before {
    border-color: #CBCBCB;
  }
}