.field {
  margin: 15px 0;
}

.searchBlock {
  height: 108px;
  position: relative;
  margin-bottom: 15px;
}

.btn {
  position: absolute;
  top: 55px;
  right: 0;
  margin-top: 20px;
}
