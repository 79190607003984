.container {
  flex-grow: 1;
  position: relative;
}

.inputContainer {
  position: relative;
}
.icon {
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 6px;
  bottom: 0;
  z-index: 0;
  margin: auto;
  height: 12px;
  width: 13px;
  color: #0032A0;
}

.spinner {
  position: absolute;
  top: 0;
  right: 5px;
  bottom: 0;
}

.suggestion {
  padding: 5px 10px;
}
