@import 'src/modules/common/styles/utils/variables';

.container {
  position: relative;
}
//burguer
.burger {
  background: none;
  border: 0 none;
  padding: 0;
  margin: 0;
  height: 44px;
  width: 42px;
  border-radius: $card-border-radius;
  background-color: $white;
  &.active {
    background-color: $black !important;
    & svg {
      color: $white;
    }
  }
  i {
    width: 18px;
    height: 20px;
  }
  svg {
    color: $black;
  }
}
//content of first pop-out
.content {
  position: absolute;
  z-index: 10;
  width: 280px;
  right: 0px;
  left: unset;
  top: 50px;
  outline: none;
  box-shadow: $box-shadow-modal-2;
  border-radius: $card-border-radius;
  border: 1px solid $modal-border-2;
  background-color: $white;
  .preferencesContent {
    height: 35px;
    margin-top: 10px;
  }
  .logoutButton, .preferencesButton {
    width: 100%;
    height: 35px;
    justify-content: space-between;
    background-color: inherit;
    padding: 10px 15px;
    border: none;
    color: $black;
    margin-bottom: 10px;
    &:active, &:focus {
      background-color: inherit;
      color: $black;
    }
    &:hover {
      background-color: $lt-gray-2;
      color: $black;
    }
    .logoutIcon {
      width: 15px;
      height: 15px;
    }
    .preferencesIcon {
      width: 18px;
      height: 16px;
    }
  }
  .linkContent{
    border-bottom: 1px solid #cbcbcb;
  .linkButton {
      width: 100%;
      height: 35px;
      justify-content: space-between;
      background-color: inherit;
      padding: 10px 15px;
      border: none;
      color: #0032A0;
      border-radius: 0;
      margin-bottom: 10px;
      &:active, &:focus {
        background-color: inherit;
      }
      &:hover {
        background-color: $lt-gray-2;
        border-left: solid black 2px;
      }
      .logoutIcon {
        width: 15px;
        height: 15px;
      }
      .preferencesIcon {
        width: 18px;
        height: 16px;
      }
      .mdhqLinks{
        padding-left: 10px;
      }
    }
  .mdhq{
    color: black;
  }
  .mdhqLinks{
    padding-left: 46px;
    i{
      display: none;
    }

    &:hover {
      i{
        display: block;
      }
    }
  }
  .knownIssuesLink{
    i{
      display: none;
    }

    &:hover {
      i{
        display: block;
      }
    }
  }
  }
  .logoutButton {
    margin-top: 10px;
  }
}
