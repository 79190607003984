.container {
  composes: item from '/src/@grow-components/DropdownMenu/DropdownMenu.module.scss';
  
  &:hover {
    border-left: solid 2px black;
    .submenu{
      display: block;
    }
  }
}
.submenuCaret {
  composes: submenuCaret from '/src/@grow-components/DropdownMenu/DropdownMenu.module.scss';
}
.submenu {
  composes: shadow from global;
  position: absolute;
  top: 0;
  right: 103%;
  display: none;
}