@import 'src/modules/common/styles/utils/variables';

.container {
  line-height: 18px;
}

.label {
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: $legato-gray-dark;
  line-height: 18px;
}

.value {
  font-weight: 400;
  color: $second-black;
  font-size: 13px;
  line-height: 18px;
}

.lg {
  .value {
    font-size: 16px;
  }
}
