@import "src/modules/common/styles/utils/variables";

.wrapper {
  height: var(--small-thumbnail-height);
  width: var(--small-thumbnail-width);
  position: relative;

  .round {
    border-radius: 50%;
  }

  img {
    object-fit: contain;
    border-radius: 6px;
  }

  .img-expanded-wrapper {
    border: 20px solid $white;
    background-color: $white;
    box-shadow: 12px 12px 24px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    position: absolute;
    left: calc(100% + 10px);
    width: max-content;
    height: max-content;
    z-index: -1;
    opacity: 0;
    &.centred {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }
  }

  .img-expanded {
    border-radius: 6px;
    box-sizing: border-box;
    position: relative;
    opacity: 0;
    max-width: 450px;
    max-height: 450px;
  }

  .img-source {
    position: absolute;
    margin-top: 10px;
    opacity: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $gray-dark;
    font-size: 9px;
    line-height: 11px;
  }

  .img-expanded-wrapper, .img-expanded, .img-source {
    transition: all .2s ease;
    transition-delay: 1s;
  }

  &:hover {
    .img-expanded-wrapper {
      z-index: 100;
      opacity: 1;
    }

    .img-expanded {
      opacity: 1;
    }

    .img-source {
      opacity: 1;
    }
  }
}
