@import 'src/modules/common/styles/utils/variables';

.menuWrapper {
  position: relative;
  z-index: 10;
}

.list {
  position: absolute;
  right: 0;
  min-width: 160px;
  width: auto;
  padding: 23px 0;
  background: var(--white);
  border: 1px solid #CBCBCB;
  border-radius: 6px;
  composes: shadow from global;
  z-index: 10;
}

.submenu {
  top: 0;
  right: 103%;
}

.submenuCaret {
  margin-right: 10px;
}

.item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  width: auto;
  padding: 12px;
  height: 35px;
  cursor: pointer;
  color: $second-black;
  border-left: solid 2px white;

  &:hover {
    background: var(--lt-gray);
    border-left: solid 2px black;
  }
}