@import 'src/modules/common/styles/utils/variables';

.historyCard {
  border-radius: var(--card-radius);
  padding: 20px;
}

.historyCount {
  width: 100%;
  font-size: 11px;
  padding: 0 0 8px 8px;
  position: sticky;
  top: var(--history-count-top);
  background-color: $lt-blue;
  color: $second-black;
  line-height: 18px;
}
.activeTop {
  top: 143px;
}

.historyTable {
  composes: table from global;
  border-top: 0 none;
  padding: 20px;
  margin-top: 13px;
  color: $second-black;
  font-size: 11px;

  & tbody > tr {
    &:first-child > td {
      border-top: none;
    }
  }

  & tbody > tr > td {
    padding: 8px;
  }

  & tbody > tr > td {
    &:nth-child(2){
      width: 400px;
      text-indent: 60px;
    }
    &:nth-child(3){
      width: 300px;
      text-indent: 15px;
    }
  }
}

.timeWidth {
  width: 80px;
  text-align: center;
}

.actionsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  order: 0;
  padding: 10px;
  position: sticky;
  top: 60px;
  background: $lt-blue;
  z-index: 2;
  gap: 34px;
}

.arrow {
  color: $success-green;
  margin: 0 10px;
}
.sortBtn {
  padding: 2.5px 8px !important;
}
// this is pretended to be changed in this task: https://wmggt.atlassian.net/browse/GP-3847
.textWrapper {
  overflow-wrap: break-word;
  inline-size: 400px;
}
@media (max-width: 1200px) {
  .textWrapper {
    inline-size: 300px;
  }
}
@media (max-width: 900px) {
  .textWrapper {
    inline-size: 250px;
  }
}
