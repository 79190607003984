@import 'src/modules/common/styles/utils/variables';

.backButton {
  font-weight: 600;
  color: $legato-branded-primary;
  line-height: 16px;
}

.backIcon {
  margin-bottom: 1px;
  color: $legato-branded-primary;
}