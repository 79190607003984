@import 'src/modules/common/styles/utils/variables';

.bannerContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.bannerIcon {
  margin-right: 10px;
}

.historyButtonTooltip {
  margin-right: 17px;
}
.editButtonContainer .editButton {
  padding: 6px 10px;
}
.republishButtonTooltip {
  max-width: 200px;
}