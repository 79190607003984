@import '../utils/variables';

.artist-page__spinner {
  z-index: $z-index-2;
}

.artist-page {
  position: relative;
  h2 {
    font-size: 26px;
  }
  .well {
    h2 {
      margin-top: $space-1;
      margin-bottom: $space-4;
    }
    label,
    input,
    p {
      font-size: 16px;
    }

    .react-tooltip {
      p {
        font-size: 13px;
      }
    }
  }
  .well .plaintext-wrapper,
  .isni-select__label-input {
    border-top: 1px solid $light-border;
    label {
      margin: 0.75rem 0 0.75rem 0.75rem;
    }
    &:last-child {
      border-bottom: 1px solid $light-border;
    }
  }

  .artist-header__text-wrapper {
    h1 {
      margin-top: 0;
      margin-bottom: 0;
    }
    div {
      cursor: pointer;
    }
    .fas {
      margin-right: $space-1;
    }
    span {
      line-height: 1.1;
    }
    margin-top: $space-5;
    margin-bottom: $space-5;
  }

  .artist-data-source {
    display: inline-block;
  }

  .quansic-data {
    border-left: none;
    border-right: none;
    border-bottom: none;
    padding-top: 0;
    .label-value-row {
      label,
      p {
        margin-bottom: 0;
      }
      &:not(:last-of-type) {
        border-bottom: 1px solid $light-border;
      }
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .labels {
      label {
        display: block;
      }
    }
    .values {
      p {
        margin-bottom: $space-1;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
    }
  }
  .fa-times-circle,
  .fa-search {
    color: $lt-black-3;
    &:hover {
      color: $accent-primary;
    }
  }

  .isni-input-wrapper {
    min-width: 50%;
    .isni-input-wrapper__display {
      width: 18ch;
      padding: 10px 0;

      border: none;
    }
    .btn-func-default {
      color: $drk-gray-1;
      border: none;
      padding-left: $space-1;
      padding-right: $space-1;
      &:hover {
        color: $accent-primary;
      }
    }
  }
  .isni-select__wrapper {
    .isni-select__isni-label {
      margin-bottom: 0;
    }
    .isni-select__label-input {
      align-items: flex-start;
      justify-content: space-between;
    }
    .isni-input {
      p {
        margin-bottom: 0;
      }
      .icons {
        display: flex;
        align-items: center;
      }
      .btn-link {
        padding: 0;
      }
    }

    .results-wrapper {
      max-height: 80vh;
      overflow: scroll;
    }
    .input-with-buttons {
      display: flex;
      border: 1px solid $md-gray-2;

      input {
        min-width: 20ch;
        border: none;
      }
    }
  }

  .result__btns {
    margin-bottom: -($space-1);
    .btn {
      margin-bottom: $space-1;
    }
  }

  .mock-input {
    p {
      margin: 0;
    }
  }
  .quansic-data-wrapper {
    h2 {
      margin-top: 0;
    }
    .quansic-button-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      flex-grow: 1;
    }
  }
  .quansic-label {
    display: inline-flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;
    padding: $space-1;
    margin-top: $space-1;
    p {
      margin: 0;
    }
  }
  $artist-image-size: 120px;
  .artist-image-wrapper {
    height: $artist-image-size;
    width: $artist-image-size;

    img {
      max-height: $artist-image-size;
      max-width: $artist-image-size;
      object-fit: cover;
    }
  }
  .disabled-p {
    color: $md-gray-2;
  }
  .look-up-artist-btn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }
  .audit-history-tag {
    color: $lt-black-3;
  }
}

// MORE INFO
.more-info {
  .artist-links {
    .sub-well {
      border-left: none;
      border-right: none;
      margin-bottom: 0;
      border-bottom: none;
    }
  }
  label {
    margin-left: 0;
  }

  .more-info-container {
    .cancel-btn {
      position: absolute;
      right: 0;
      margin-right: $space-4;
      cursor: pointer;
      i {
        color: $black;
      }
      &:hover {
        i {
          color: $accent-primary;
        }
      }
    }
    top: 100px;
    position: relative;
    overflow: scroll;
    max-height: calc(100vh - 100px);
    button {
      border: none;
    }
  }
  .heading {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .heading__right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      color: $lt-black-3;
      p {
        margin: 0;
        margin-top: $space-3;
      }
    }
  }
  $artist-image-size: 120px;
  .artist-image-wrapper {
    max-height: $artist-image-size;
    max-width: $artist-image-size;
    img {
      max-height: $artist-image-size;
      max-width: $artist-image-size;
      object-fit: cover;
    }
  }
  .artist-identifiers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    label {
      margin-bottom: 0;
    }
    .external-link {
      display: flex;
      align-items: center;
      i {
        margin-left: $space-1;
      }
      button {
        position: inherit;
        border: none;
        margin-left: $space-2;
        p {
          margin-bottom: 0;
          color: $accent-primary;
        }
      }
    }
  }
  .panel-heading {
    cursor: pointer;
  }
  .accordion__panel {
    overflow: auto;
    max-height: 450px;
  }
  .accordion__button {
    justify-content: space-between;
    align-items: center;
    i {
      color: $lt-black-3;
      font-size: 16px;
    }
  }
  thead tr th {
    background-color: rgba($accent-secondary, 0.5);
  }
}

@media (min-width: 768px) {
  .more-info {
    .more-info-container {
      width: 80vw;
      max-height: 80vh;
    }
  }
}
@media (min-width: 1200px) {
  .more-info {
    .more-info-container {
      width: 1000px;
      margin: 0 auto;
    }
  }
}

@media (max-width: 768px) {
  .quansic-data {
    .quansic-row {
      flex-wrap: wrap;
    }
  }
}

// OVERLAP WARNING
.overlap-warning {
  box-shadow: inset 2px 0 0 0 $warning-solid;
  background-color: $warning-soft;
  h3 {
    color: $warning-solid;
    font-size: 19px;
  }
  p {
    margin-bottom: 0;
  }
}
