.table {
  composes: table from global;
  border-top: 0 none;

  & .header {
    & th {
      z-index: 3;
      position: sticky;
      top: var(--search-results-table-top);
      &:hover {
        z-index: 11;
      }
    }
  }
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 120px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    opacity: 0.5;
  }
}

.noResultsMessage {
  padding-top: 70px;
  text-align: center;
  font-size: 26px;
  line-height: 1.2;
}
