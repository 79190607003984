.uaid {

  margin-left: 6px;
}

.content {
  display: flex;
  flex-direction: row;
  gap: 57px;
}

.table {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  td, th {
    width: 33%;
  }
}
