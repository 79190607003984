@import "src/modules/common/styles/utils/variables";

.recentPartiesWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 30px;
  row-gap: 30px;
  padding: 15px 30px 30px;
}

.spinnerWrapper {
  padding: 35px;
}

.emptyRecentParties {
  width: 100%;
  text-align: center;
  margin-top: 40px;
  font-size: 16px;
  line-height: 18px;
  color: $legato-gray;
}
