@import "src/modules/common/styles/utils/variables";

.subheader, .additionalInfo, .infoBlock {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
}
.subheader {
  display: flex;
  padding-top: 40px;
  background-color: $white;
  margin-bottom: 40px;
  z-index: 2;
  min-height: 100px;
  max-height: fit-content;
  &.shrink {
    top: 38px;
  }
}

.sticky {
  position: sticky;
  top: 60px;
}

.infoBlock {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  word-break: break-word;
}

.rightSideBlock {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}

.paginationBlock {
  width: 70%;
}

.location {
  font-size: 32px;
  line-height: 34px;
  color: $legato-gray;
  font-weight: 600;
}

.mainInfo {
  font-size: 32px;
  font-weight: 600;
  min-height: 35px;
  line-height: 34px;
  color: $black;
}
.additionalInfo {
  position: relative;
  font-size: 11px;
  line-height: 14px;
  bottom: -10px;
  min-height: 12px;
  color: $second-black;
}

.hidden {
  opacity: 0;
  bottom: 14px;
}

.divider {
  opacity: 0;
  width: 100vw;
  position: fixed;
  top: 161px;
  left: calc(-50vw + 50%);
  margin-top: -1px;
  border-bottom: 1px solid #e5e5e5;
  z-index: 4;
}

.dividerShown {
  opacity: 1;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
}