.moreInfoBtn {
  margin-left: 20px;
  height: 35px;
  width: 124px;
  font-family: var(--default-font-family, 'SuisseIntl');
}

.partyImage {
  font-size: 5px;

  img {
    position: absolute;
    max-height: var(--small-thumbnail-height);
    max-width: var(--small-thumbnail-width);
    object-fit: cover;
  }

}