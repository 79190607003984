.thumbnail {
  --small-thumbnail-width: 140px;
  --small-thumbnail-height: 140px;
  width: calc(var(--small-thumbnail-width) + 10px);
  padding: 0 20px 0 10px;
}

.identifiersColumn{
  display: flex;
  justify-content: space-evenly;
}

.text {
  color: '#008642'
}


.partyImage {
  img {
    position: absolute;
    max-height: var(--small-thumbnail-height);
    max-width: var(--small-thumbnail-width);
    object-fit: cover;
  }
}

