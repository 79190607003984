.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  background-color: #F2F2F2;
  height: 230px;
}

.headline {
  font-size: 26px;
  line-height: 29px;
  font-weight: 600;
  margin-bottom: 30px;
}

.messageWrapper {
  min-height: 75px;
}

.message {
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 20px;
}