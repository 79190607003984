.list {
  position: absolute;
  left: 0;
  min-width: 100%;
  padding: 0;
  background: #fff;
  composes: shadow from global;
  z-index: 10;
}
.item {
  list-style-type: none;
}
