.container {
  height: 21px;
  line-height: 21px;
  background: var(--lt-black);
  color: #fff;
  border-radius: 10px;
  padding: 0 0 0 12px;
  display: flex;
  align-items: center;
}
.label {
  line-height: normal;
  align-self: center;
}
.btn {
  border: 0 none;
  appearance: none;
  background: none;
  line-height: 21px;
  height: 21px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
