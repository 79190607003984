.title {
  padding: 0 10px;
  text-transform: uppercase;
  font-size: 9px;
  color: #000000;
  margin: 10px 0 0 0;
}
.container {
  min-width: 360px;
  z-index: 99999;
}