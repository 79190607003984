@import 'src/modules/common/styles/utils/variables';

.partyFieldsBlock {
  padding: 0 30px 40px;
}

.dateBlock {
  &__title {
    display: inline-block;
    color: #3f3f3f;
    font-weight: 400;
  }
}

.helperTooltip {
  max-height: 80px;
  max-width: 540px;
  word-break: break-all;
}

.helperIcon {
  color: $accent-primary;
  font-size: 13px;
  margin: 0 15px;
}

.helperHeading {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  color: $accent-primary-lt;
  margin-bottom: 4px;
}

.dateInput {
  padding: 6px 10px;
  width: 75px;
}

.cancelModalContent {
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.saveButtonDiv {
  display: flex;
  align-items: center;
  &:hover {
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  }
  .saveButton {
    padding: 6.5px 14px 5.5px 15px;
    border-radius: 6px 0px 0px 6px;
    border-right-width: 0px;
    box-shadow: none !important;
    &:hover{
      border: 1px solid #0032A0;
      border-right-width: 0px;
      background-color: #E6EBF6;
    }
    span{
      width: 26px;
      height: 13px;
      font-size: 11px;
      line-height: 13px;
      font-weight: 600;
    }
  }
}

.saveIcon{
  font-size: 13px;
  font-weight: 300;
  line-height: 13px;
}

.dropdownButton {
  padding: 6.5px 14px 5.5px 15px !important;
  border-radius: 0px 6px 6px 0px !important;
  &:hover {
    box-shadow: none !important;
  }
  span {
    display: none;
  }
}

.signedDiv{
  display: flex;
  flex-direction: column;
  width: auto!important;
  max-width: none!important;
  span:first-child{
    font-weight: 600;
    margin-left: 10px;
  }
}

.checkboxSigned{
  margin: 10px 10px;
  font-weight: 400;
}

.submitButton {
  padding: 6px 10px;
}

.submitButton, .linkButton {
  padding: 6px 10px!important;
  font-size: 13px;
}
.linkButton {
  margin-right: 10px;
}
.addButton {
  font-weight: 600;
  color: $legato-branded-primary;
}
