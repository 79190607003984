@import 'src/modules/common/styles/utils/variables';

.select {
  background-color: #fff;
  padding: 5px 6px;
}
.container {
  position: relative;
}
.inputContainer {
  cursor: text;
  &::after {
    content: '';
    display: block;
    position: absolute;
    inset: 0 0 0 0;
    user-select: none;
    z-index: 10;
  }
  &:hover {
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  }
}
.placeholder {
  color: #c1c4cc;
  font-style: italic;
  option {
    color: #000;
    font-style: normal;
  }
}
.wrapperIcon {
  position: relative;
  .customIcon {
  position: absolute;
  top: calc(50% - 7px);
  right: 12px;
  pointer-events: none;
  color: #0032A0;
  }
}
.chevronHidden {
  appearance: none;
  padding: 5px 10px;
}
.icon {
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 17px;
  bottom: 0;
  z-index: 1;
  margin: auto;
  height: 12px;
  width: 13px;
  color: $second-black;
  transition: 0.3s transform ease-in-out;
  &--open {
    transform: rotate(-180deg);
    transition: 0.3s transform ease-in-out;
    pointer-events: none;
  }
}
.trigger {
  display: flex;
  align-items: center;
  cursor: default;
  &[readonly] {
    height: 35px;
    padding-left: 15px;
    background-color: #fff;
    color: $black;
    user-select: none;
  }
  span {
    flex: 1;
  }
}

.triggerOpen  {
  @extend .trigger;
  &[readonly] {
    @extend .trigger[readonly];
    border-bottom-color: black;
  }
}

