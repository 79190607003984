.wrapper {
  line-height: 0;
  color: var(--warning-yellow);
  position: relative;
  --background-color: #000;
}
.popover {
  composes: popover from '/src/@grow-components/Popover/popover.module.scss';
  z-index: unset;
  max-width: 320px;
  width: 320px;
  color: #fff;
  white-space: normal;
  font-size: 13px;
  line-height: 1.18;
  padding: 15px;
  opacity: 0.01;
  transition: 0.3s opacity ease-in-out;
  transition-delay: 0.2;
  visibility: hidden;
  p:last-child {
    margin: 0;
  }
  &:focus, &:hover {
    opacity: 1;
    visibility: visible;
  }
}

.icon:hover + div {
  visibility: visible;
  opacity: 1;
}

.focused {
  visibility: visible;
  opacity: 1;
}