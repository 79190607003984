.container {
  position: relative;
  line-height: 0;
  --background-color: #fff;
}
.btn {
  background: url("./history.svg") scroll no-repeat center center;
  width: 20px;
  height: 20px;
  border: 0 none;
  padding: 0;
  position: relative;
}
.popover {
  position: absolute;
  background: var(--background-color);
  max-width: 90vw;
  white-space: nowrap;
  padding: 22px;
  border-radius: 4px;
  z-index: 999;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
  line-height: 18px;
  font-size: 11px;
  &::after, &::before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    position: absolute;
    margin: auto;
  }
  &.top {
    &::before, &::after {
      left: 0;
      right: 0;
    }
    &::before {
      bottom: -17px;
      border-top-color: #ced6dc;
    }
    &::after {
      bottom: -16px;
      border-top-color: var(--background-color);
    }
  }
  &.bottom {
    &::before, &::after {
      left: 0;
      right: 0;
    }
    &::before {
      top: -17px;
      border-bottom-color: #ced6dc;
    }
    &::after {
      top: -16px;
      border-bottom-color: var(--background-color);
    }
  }
  &.left {
    &::before, &::after {
      top: 0;
      bottom: 0;
    }
    &::before {
      right: -17px;
      border-left-color: #ced6dc;
    }
    &::after {
      right: -16px;
      border-left-color: var(--background-color);
    }
  }
  &.right {
    &::before, &::after {
      top: 0;
      bottom: 0;
    }
    &::before {
      left: -17px;
      border-right-color: #ced6dc;
    }
    &::after {
      left: -16px;
      border-right-color: var(--background-color);
    }
  }
  &.right-bottom {
    &::before, &::after {
      left: 20px;
    }
    &::before {
      top: -17px;
      border-bottom-color: #ced6dc;
    }
    &::after {
      top: -16px;
      border-bottom-color: var(--background-color);
    }
  }
  &.right-top {
    &::before, &::after {
      left: 20px;
    }
    &::before {
      bottom: -17px;
      border-top-color: #ced6dc;
    }
    &::after {
      bottom: -16px;
      border-top-color: var(--background-color);
    }
  }
}
