@import 'src/modules/common/styles/utils/variables';

.tabsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tabs {
  flex-direction: row;
  flex-grow: 1;
  height: 58px;
  gap: 45px;
  width: auto!important;
  display: flex;
  align-items: center;
}

.tabItem {
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  padding: 14px;
  color: $drk-gray-1;
  font-weight: 600;

  &_current {
    border-bottom: 2px solid $black;
    color: $black;
  }
}

.sticky {
  position: sticky;
  top: 60px;
  z-index: 1;
}