@import "src/modules/common/styles/utils/variables";

.container {
  background: $white;
  margin: 12px 20px 20px;
  border-radius: 6px;
  flex-grow: 1;
  padding: 30px 20px;
}

.header {
  margin-bottom: 20px;
}

.content {
  font-size: 16px;
  line-height: 22px;
  color: $legato-gray-dark;
}

.footer {
  font-size: 11px;
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.author {
  color: $legato-gray;
  line-height: 14px;
}

.date {
  line-height: 14px;
  color: $modal-border-2;
}
