.list {
  padding: 0;
  margin-top: 10px;
  margin-bottom: 0;
}
.item {
  display: inline-block;
  margin-right: 10px;
  margin-top: 15px;
  &:last-child {
    margin-left: 0;
  }
}
