.btn {
  border: 0 none;
  background: none;
  padding: 0;
  transition: 0.3s all ease-in-out;
  will-change: all;
  &:disabled {
    color: var(--lt-gray-2);
  }
  &:focus {
    outline: 1px solid var(--accent-primary);
  }
}
.close-btn {
  composes: btn;
  position: relative;
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
  align-self: center;
  &:before, &:after {
    content: '';
    height: 2px;
    width: 100%;
    background: rgba(0, 0, 0, 0.36);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

.action-btn {
  composes: btn from '/src/@grow-components/Button/Button.module.scss';
  composes: btn_large from '/src/@grow-components/Button/Button.module.scss';
  composes: btn_outline from '/src/@grow-components/Button/Button.module.scss';
}
.cancel-btn {
  composes: btn from '/src/@grow-components/Button/Button.module.scss';
  composes: btn_large from '/src/@grow-components/Button/Button.module.scss';
  composes: btn_link from '/src/@grow-components/Button/Button.module.scss';
  composes: btn_black from '/src/@grow-components/Button/Button.module.scss';
}
