@import '~react-toastify/dist/ReactToastify.min.css';

 //All variable and style use from Legato library Growl component
:root {
  --toastify-toast-min-height: 50px;
  --toastify-color-info: #0032a0;
  --toastify-color-success: #00813d;
  --toastify-color-warning: #b45400;
  --toastify-color-error: #d42b3a;
  --toastify-text-color-info: #ffffff;
  --toastify-text-color-success: #ffffff;
  --toastify-text-color-warning: #ffffff;
  --toastify-text-color-error: #ffffff;
}

/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  width: 100%;
  overflow-y: auto;
  align-items: flex-end;
  gap: 10px;
  padding: 20px;
  pointer-events: none;
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
}

.Toastify__toast-container--top-center {
  top: 70px;
}

.Toastify__toast-container--top-right {
  top: 0;
}

.Toastify__toast-container--bottom-left {
}

.Toastify__toast-container--bottom-center {
}

.Toastify__toast-container--bottom-right {
}

/** Classes for the displayed toast **/
.Toastify__toast {
  pointer-events: all;
  position: relative;
  display: inline-flex;
  box-sizing: border-box;
  width: 262px;
  min-height: 65px;
  font-size: 13px;
  line-height: 16px;
  border-radius: 6px;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.2);
  padding: 20px;
  gap: 10px;
  margin-bottom: 0;
  font-family: var(--default-font-family, "SuisseIntl");
}

.Toastify__toast--rtl {
}

.Toastify__toast-body {
  display: flex;
  max-width: 220px;
  padding: 0 !important;
  gap: 10px;
  align-items: baseline !important;
}

/** Used to position the icon **/
.Toastify__toast-icon {
  width: 10px !important;
}

.Toastify--animate-icon {
  animation-fill-mode: both !important;
  animation-duration: .1s !important;
}

/** handle the notificaiton color and the text color based on the theme **/
.Toastify__toast-theme--dark {
}

.Toastify__toast-theme--light {
}

.Toastify__toast-theme--colored.Toastify__toast--default {
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  @extend .Toastify__toast;
  background: #0032a0 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  @extend .Toastify__toast;
  background: #00813d !important;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  @extend .Toastify__toast;
  background: #b45400 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  @extend .Toastify__toast;
  background: #d42b3a !important;
}

.Toastify__progress-bar {
}

.Toastify__progress-bar--rtl {
}

.Toastify__progress-bar-theme--light {
}

.Toastify__progress-bar-theme--dark {
}

.Toastify__progress-bar--info {
}

.Toastify__progress-bar--success {
}

.Toastify__progress-bar--warning {
}

.Toastify__progress-bar--error {
}

/** colored notifications share the same progress bar color **/
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
  cursor: pointer;
  font-size: 16px;
  color: #ffffff
}

.Toastify__close-button--default {
}

.Toastify__close-button > svg {
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
}

.fade-in {
  animation: fade-in .1s linear both;
}

.fade-out {
  animation: fade-out .1s linear both;
}

@keyframes fade-in {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@keyframes fade-out {
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
}
