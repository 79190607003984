@import "src/modules/common/styles/utils/variables";

.formRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .label {
    color: $second-black;
    flex-basis: 110px;
    flex-shrink: 0;
    font-weight: 400;
    line-height: 18px;
  }
  
  .controls {
    flex-grow: 1;
    display: flex;
    min-width: 356px;
    max-width: 356px;
    width: 100%;
  }

  .control {
    margin: 0;
    flex-grow: 1;
    flex-basis: 60%;
    min-width: 126px;
    
    &.small {
      flex-basis: 30%;
      flex-shrink: 0;
    }
  }

  & + & {
    margin-top: 20px;
  }
}