@import "src/modules/common/styles/utils/variables";

.container {
  position: relative;
}
.btn {
  height: 38px;
  color: $modal-confirm-color;
  background: none;
  border: none;
  appearance: none;
  position: relative;
  &:disabled {
    color: $legato-gray;
  }
  .buttonContent {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
.link {
  cursor: pointer;
  padding: 0 11px 0 26px;
  height: 38px;
  white-space: nowrap;
  text-decoration: none;
  display: flex;
  align-items: center;
  line-height: 38px;
  font-size: 11px;
  color: $second-black;
  transition: 0.3s background, color ease-in-out;
  .optionIcon {
    flex-shrink: 0;
    color: $modal-confirm-color;
    position: relative;
    margin-left: 10px;
  }
  &:hover, &:focus, {
    color: $white;
    background: var(--accent-primary);

    .optionIcon {
      color: $white;
    }
  }
  &:active {
    color: $white;
    background: var(--accent-primary-hover);

    .optionIcon {
      color: $white;
    }
  }
}

.list {
  right: 0;
  left: unset;
  outline: none;
  padding: 7px 0;

}
