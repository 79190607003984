@import 'src/modules/common/styles/utils/variables';

.userContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 14px;
  margin-bottom: 3px;
  }
  .descriptionContainer {
    margin-left: 11px;
    .name {
      color: $black;
      font-weight: 600;
    }
    p , span {
      color: #646464;
      font-weight: 600;
    }
  }