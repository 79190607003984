.isni-item {
  &__label {
    max-width: 100px;
    width: 10%;
    font-family: unset;
    font-weight: 600;
    line-height: 14px;
    color: #383838;
  }

  &__link {
    color: #383838;
    &:hover {
      color: #0032a0;
    }
  }

  &__value {
    width: 30%;
    line-height: 14px;
    color: #383838;

    &.clip {
      &:hover {
        cursor: default;
        color: #0032a0;

        .clip-icon {
          opacity:1;
          cursor: pointer;
        }
      }

      .clip-icon {
        opacity: 0;
      }
    }
  }

  &__headline {
    padding: 15px 0;
    margin: 12px 0 20px;
    background: #f4f4f4;

    .search-param {
      padding: 0;
    }

    .headline-bold {
      display: flex;
      align-items: center;
      font-weight: 600;
    }

    .isni-currently-assigned {
      display: flex;
      align-items: center;
      margin-left: 20px;
      padding: 0;
      font-weight: normal;
    }
  }

  &__group {
    margin-bottom: 20px;
  }

  &__data {
    display: flex;
    flex-direction: column;
  }

  &__li {
    display: flex;
    flex-direction: row;
    padding-bottom: 8px;
    margin: 0;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    padding: 0 10px 35px 101px;

    .margin-left {
      margin-left: 20px;
    }
  }
}

.quansic-source {
  text-align: right;
  color: var(--drk-gray-1);
  font-size: 18px;
}

@media (max-width: 992px) {
  .isni-item {
    &__label {
      width: 15%;
    }
  }
}
@media (max-width: 768px) {
  .isni-item {
    &__label {
      width: 20%;
    }
  }
}
