@import 'src/modules/common/styles/utils/variables';

.partyPreview {
  display: flex;
  flex-direction: row;
  align-items: center;

  .partyImageSmall {
    width: 42px !important;
    height: 42px !important;
    font-size: 2.4px;

    img {
      width: 42px;
      height: 42px;
      object-fit: initial;
    }
  }

  .partyInfo {
    margin-left: 13px;
    display: flex;
    flex-direction: column;

    .partyLink {
      font-size: 11px;
      line-height: 18px;
      color: $legato-branded-primary;
      font-weight: 600;
      width: 137px;
      height: 2rem;
      overflow: hidden;
    }

    .date {
      font-size: 11px;
      line-height: 18px;
      color: $second-black;
      font-weight: 600;
    }
  }
}