@import "../../../../../modules/common/styles/utils/variables";

.search-results-header {
  display: flex;
  &__breadcrumbs {
    padding-bottom: 30px;
    color: $md-gray;

    .current {
      color: $accent-link
    }
  }
}
