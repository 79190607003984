@import 'src/modules/common/styles/utils/variables';

.container {

}

.inputsArray {

}

.header {
  margin-bottom: 5px;
}

.title {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-family: var(--default-font-family, "SuisseIntl");
  font-size: 13px;
  line-height: 18px;
  color: $black;
}
