.container {
  display: inline-flex;
  width: 100%;
  position: sticky;
  padding: 0 0 10px 0;
  top: 118px;
  background: #eff8ff;
}
.item {
  list-style-type: none;
}