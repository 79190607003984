.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.linksBlock {
  display: flex;
  flex-direction: column;
}

.links {
  display: flex;
  flex-direction: column;
  margin: 15px 0 0 30px;
}

.link {
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 14px;
  color: var(--primary);
}