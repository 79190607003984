@import '../utils/variables';

.landing-page {
  h4 {
  }
  .party-list {
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow-y: auto;
    a {
      &:first-of-type {
        color: $success-green;
      }
    }
  }
}
