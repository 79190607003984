.container {
  display: flex;
  align-items: center;
  padding: 3px 10px;
  cursor: pointer;
}
.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title {
  composes: text;
  color: #3f3f3f;
  flex: 1 0 170px;
}
.description {
  composes: text;
  color: #71767d;
  padding-left: 10px;
  flex: 0 0 140px;
}
.iconHolder{
  flex-basis: 30px;
}
