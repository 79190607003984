.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.thumbWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.exclamation {
  font-size: 32px;
  line-height: 37.5px;
  margin-bottom: 5px;
}

.tree {
  font-size: 64px;
  line-height: 75px;
}

.musicSlash {
  font-size: 64px;
}

