@import 'src/modules/common/styles/utils/variables';

.container {
  font-size: 11px;
  color: $second-black;
  & > div {
    display: inline-block;
  }
}

.timestamp {
  cursor: default;
}

.created {
  font-weight: 600;
  color: $black;
}

.edited {
  font-weight: 400;
}

.tooltipBody {
  font-size: 11px;
}

.tooltipRow {

  & + & {
    margin-top: 1em;
  }
}
