@import 'src/modules/common/styles/utils/variables';

.inputLabel {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: $black;
  font-family: var(--default-font-family, "SuisseIntl");
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}

.iconInLabel {
  display: inline-flex;
  text-align: center;
  color: $legato-branded-primary;
}

.warningIcon {
  display: flex;
  align-items: center;
  font-size: 13px;
  text-align: center;
  color: #e8be04 !important
}

.errorIcon {
  font-size: 13px;
  text-align: center;
  color: #e8be04 !important
}

.labelSpinner {
  display: inline-flex;
  margin-left: 5px;
}
