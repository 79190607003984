.container {
  position: relative;
}

.link {
  padding: 0 26px;
  height: 40px;
  border-left: 2px solid transparent;
  white-space: nowrap;
  text-decoration: none;
  display: block;
  line-height: 38px;
  color: var(--lt-black);
  transition: 0.3s background, color ease-in-out;
  &:hover, &:focus, {
    color: #000;
    background: #e5e3e3;
    text-decoration: none;
    border-left: 2px solid #000;
  }
  &:active {
    color: #fff;
    background: var(--accent-primary-hover);
    text-decoration: none;
  }
}
.linkContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.linkIcon {
  width: 16px;
  height: 16px;
  color: #000 !important;
  margin-right: 10px;
}
.list {
  right: 20px;
  left: unset;
  top: 40px;
  outline: none;
  padding: 15px 0;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}
