.headerContainer {
  display: grid;
  grid-template-columns: 1fr minmax(350px, 890px);
  margin-bottom: 50px;
}

.isniValue {
  font-weight: 600;
  color: #383838;
}
.artistAvatar {
  width: 184px;
  height: 184px;
  position: relative;
  margin-right: 100px;
  & > img {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    object-fit: cover;
    object-position: top;
  }
}

.banner {
  padding: 8px 0;
  background-color: #FFF2CC;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 13px;
  line-height: 16px;
}
