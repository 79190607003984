.headerActions {
  display: flex;
  align-items: center;
  gap: 45px;
}
.button {
  font-weight: 400;
}
.isniValue {
  font-weight: 600;
}