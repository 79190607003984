@import '../utils/variables';
$tip-edge: 7px;
.static-tooltip-upper-right {
  position: absolute;
  top: calc(100% + 10px);
  right: 20px;
  width: max-content;
  padding: 5px 10px;
  background-color: rgba($warning-yellow, 0.9);
  color: #fff;
  &.danger {
    background-color: rgba($warning-solid, 0.9);
  }
  &__tip {
    position: absolute;
    height: 0;
    width: 0;
    bottom: 74%;
    right: 0%;
    transform: translate(-50%, -100%);
    border-left: $tip-edge solid transparent;
    border-right: $tip-edge solid transparent;
    border-bottom: $tip-edge solid rgba($warning-yellow, 0.9);
    &.danger {
      border-bottom: $tip-edge solid rgba($warning-solid, 0.9);
    }
  }
}
.static-tooltip {
  position: absolute;
  top: -35px;
  left: 60px;
  width: max-content;
  padding: 5px 10px;
  background-color: rgba($warning-yellow, 0.9);
  color: #fff;
  &.danger {
    background-color: rgba($warning-solid, 0.9);
  }
  &__tip {
    position: absolute;
    height: 0;
    width: 0;
    top: 74%;
    left: 0%;
    transform: translate(50%, 100%);
    border-left: $tip-edge solid transparent;
    border-right: $tip-edge solid transparent;
    border-top: $tip-edge solid rgba($warning-yellow, 0.9);
    &.danger {
      border-top: $tip-edge solid rgba($warning-solid, 0.9);
    }
  }
}

