@import '../utils/variables';
/**
  @deprecated
  PLEASE DO NOT USE CLASSES FROM THIS FILE
 */
.create-party-form {
  position: relative;
  .spinner-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }

  .nametype-select {
    width: 180px;
  }

  .add-name-btn__border {
    border-top: 1px solid #c1c4cc;
    width: calc(100% - 20px);
    margin: 0 10px;
  }

  h3 {
    color: #000;
  }

  h4 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
  }

  .dob-dod-label {
    p {
      margin-bottom: 7.5px;
    }
  }

  .btn-placeholder {
    height: 35px;
  }

  input,
  textarea {
    padding-left: 10px;
  }

  input::placeholder,
  textarea::placeholder {
    color: #c1c4cc;
    font-style: italic;
  }

  select {
    padding-left: 7px;

    &.placeholder {
      color: #c1c4cc;
      font-style: italic;
    }

    option {
      color: #000;
      font-style: initial;
    }
  }

  .legal-name__label {
  }

  .module {
    border-bottom: 1px solid $md-gray-2;

    &:first-of-type {
      border-top: none;
    }

    &__top {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
    }
  }

  .name-module {
    h4 {
      margin-bottom: 0;
    }
  }

  .name-module__wrapper {
    &:last-of-type {
      .name-module {
        border-bottom: none;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: none !important;

    button {
      &:last-of-type {
        margin-left: 15px;
      }
    }
  }

  //.delete-name-btn {
  //  padding: 2.5px 8px;
  //  span,
  //  i {
  //    font-size: 11px !important;
  //  }
  //}
  .fa-exclamation-triangle.warning {
    color: $warning-yellow !important;
  }

  .fa-exclamation-triangle.danger {
    color: $warning-solid !important;
  }

  .fa-check {
    color: $success-green !important;
  }

  label {
    margin: 0;
    color: #3f3f3f;
  }

  input,
  textarea,
  select {
    height: 30px;
    border: 1px solid #d6dce3;
  }

  .form-group {
    display: flex;
    flex-direction: column;

    &__labels {
      display: flex;
      align-items: center;

      .spinner-wrapper {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        margin-left: 5px;
      }
    }

    i {
      color: #0061ab;
      font-size: 13px !important;
      margin-left: 5px;
    }
  }

  .error-message {
    color: $warning-solid;
    margin: 0;
    margin-left: 20px;
  }

  .tooltip-warning {
    background-color: $warning-yellow !important;
  }

  .tooltip-warning.place-top::after {
    border-top-color: $warning-yellow !important;
  }

  .place-right::after {
    border-right-color: $warning-yellow !important;
  }

  .tooltip-success {
    background-color: green !important;

    &.place-top::after {
      border-top-color: green !important;
    }
  }

  .checkbox-group {
    display: flex;

    input,
    textarea {
      margin: 0;
      margin-right: 10px;
    }

    align-items: center;
  }

  .confirmation-modal {
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__content {
      position: relative;
      margin-top: 50px;

      .fas {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateY(-50%);
        margin-top: -40px;
        font-size: 25px !important;
      }
    }

    p {
    }

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;

    .buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      button {
        margin-top: 25px;

        &:last-of-type {
          margin-left: 10px;
        }
      }
    }
  }

  .id-value-container {
    margin-right: 10px;
    justify-content: space-between;

    p {
      margin-left: 10px;
      margin-top: 6px;
      margin-bottom: 6px;
    }

    .btn-copy-id {
      margin-left: 10px;
      justify-content: flex-end;
      border: none;
    }
  }

  //.btn-add-translation,
  //.btn-add-competency {
  //  font-size: 11px;
  //  span {
  //    display: flex;
  //    align-items: center;
  //  }
  //  text-transform: none;
  //  .fas {
  //    font-size: 11px !important;
  //  }
  //}
  .competencies,
  .translations {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .new-remark-button {
    border: none;

    &:disabled {
      background: none !important;
    }
  }

  .remark__items {
    display: grid;
    margin-top: 10px;

    grid-auto-rows: auto;
    .remark {
      margin: 5px 10px 10px 10px;
      padding: 10px;
      border: none;
      display: flex;
      flex-direction: column;
      .remark-readonly {
        display: flex;
        flex-direction: column;
        .remark-type {
          text-transform: uppercase;
          color: #71767d;
          font-size: 9px;
          margin-bottom: 10px;
        }
        .remark-info {
          color: #71767d;
          margin-top: 7px;
          font-size: 11px;
          white-space: nowrap;
          button {
            border: none;
            &:disabled {
              background: none !important;
            }
          }
          i {
            margin-left: 6px;
          }
        }
      }
      .remark-form {
        margin-bottom: 0;
      }
      .remark-buttons {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .remark-cancel {
          color: #0061ab !important;
          border: solid 1px #d6dce3;
        }
        button {
          margin-right: 5px;
        }
      }
      input,
      textarea {
        height: 120px;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        resize: none;
      }
    }
  }

  .translations__items,
  .competencies__items {
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
    margin-left: -10px;
    margin-right: -10px;
    grid-auto-rows: auto;

    .translation,
    .competency {
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;

      input,
      textarea {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .delete-competency-btn,
  .delete-translation-btn {
    padding: 2.5px 8px;

    span,
    i {
      font-size: 11px !important;
    }
  }

  .translations__label,
  .competencies__label {
    .dots__wrapper {
      height: 18px;
      display: flex;
      flex-direction: column;

      justify-content: center;
    }
    .dots {
      border-top: 1px dotted #71767d;
      border-left: 1px solid #fff;
    }
    .static-tooltip-container {
      position: relative;
    }
    .spinner-wrapper {
      z-index: 100;
    }
  }
  .competency-warning {
    position: relative;
    i {
      margin-left: 5px;
    }
    .static-tooltip {
      left: 0;
    }
  }
}
