.container {
  display: flex;
  position: relative;
  margin: auto;
  flex-basis: 630px;
  gap: 15px;
}

.searchbarWrapper {
  flex-grow: 1;
  position: relative;
}

.advancedSearchButton {
  height: 30px;
  width: 30px;
  flex-shrink: 0;
  border-radius: 6px;
  color: #000;

  &.active {
    color: #fff;
    background-color: #000 !important;
  }
}

.advancedSearchButtonIcon {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
}

.advancedSearchContainer {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
}
