@import 'src/modules/common/styles/utils/variables';

.title {
  color: $black;
  font-family: var(--default-font-family, "SuisseIntl");
  font-weight: 600;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 5px;
}

.inputsGroup {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  &__select {
    //this apply a width manually to see the options in a better way
    --reference-width: 136px!important;
  }

  .selectYear {
    & input {
      font-size: 11.5px;
      padding: 3px;
    }
    
    & * {
      right: -2px;
    }
  }
}
