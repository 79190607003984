@import "../../modules/common/styles/utils/variables";

.remarksContainer {

}

.remarksList {

}

.container {
  background: $white;
  border: solid 1px transparent;
  padding: 10px;

  &:hover {
    border: solid 1px #d6dce3;
  }
}

.body{
  word-break: break-all;
  max-height: 86px;
  overflow: auto;
  line-height: 18px;
  color: $black
}

.wrapper {
  height: 120px;
}

.title {
  color: $legato-gray-dark;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;
}

.author,
.date {
  color: $legato-gray-dark;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
}

.date {
  display: flex;
  flex-direction: column;
}

.author {
  margin-right: auto;
}

.actions {
  margin-left: 5px;

  &:empty {
    display: none;
  }
}

.footer {
  display: flex;
  align-items: center;
}

.remarkTextarea {
  height: 120px !important;
  resize: none;
  margin-bottom: 10px;
}

.internalCommentCharacterCounter {
  z-index: 0;
  position: relative;
  width: 100%;
  height: 0 !important;
  span {
    position: absolute;
    right: 10px;
    bottom: 20px;
    font-size: 11px;
    color:#c1c4cc;
  }
  
  resize: none;
}

.formButtons {
  display: flex;
  justify-content: flex-end;
  gap: 3px;
}
