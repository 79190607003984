@import "src/modules/common/styles/utils/variables";

.container {
  display: flex;
  position: relative;
  flex-grow: 1;
  // remove conflict with the x-mark icon from legato
  button {
    line-height: normal;
  }
}

.form {
  flex-grow: 1;
  border: 1px solid $modal-border-2;
  caret-color: $modal-confirm-color;
}

.input {
   border: 0 none transparent;
 }


.suggestionsContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 1px);
  composes: shadow bg-white rounded from global;
  z-index: 1;

  &:empty {
    display: none;
  }
}

.suggestionsList {
  padding: 5px 0;
  margin: 0;
}

.suggestion {
  list-style-type: none;
  padding-left: 0;
}

.suggestionHighlighted {
  background: $accent-secondary;
}
