@import 'src/modules/common/styles/utils/variables';

.content {
  display: flex;
  color: $second-black;
  line-height: 14px;
  align-items: center;
}
.icon {
  margin-right: 10px;
  font-size: 16px;
}
.bold {
  font-weight: 600;
}