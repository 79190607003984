.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 46px;
}

.image {
  width: 183px;
  height: 183px;

  img {
    width: 183px;
    height: 183px;
    max-width: 183px;
    max-height: 183px;
  }
}

.countWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 190px;
  padding: 30px 0 0;
  background-color: var(--lt-gray-3);
}

.label {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 10px;
}

.count {
  font-size: 32px;
  line-height: 35px;
  margin-bottom: 28px;
}