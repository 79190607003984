.wrapper {
  align-items: center;
  position: relative;
  width: var(--small-thumbnail-width);
  height: var(--small-thumbnail-height);
  border: 1px solid var(--lt-gray);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #929292;
  border-radius: 6px;
}

.img-placeholder {
  color: white;
  font-size: 500%;
  position: absolute;
}

.round {
  border-radius: 50%;
}
