@import "src/modules/common/styles/utils/variables";

.footer-wrapper {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-end;

  .navbar-footer {
    text-align: left;

    width: 100%;
  }

  .navbar-text {
    p {
      &:first-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.navbar-brand {
  line-height: 22px;
}

.navbar .container .navbar-brand {
  margin-left: $logo-space;
  font-weight: 600;
  color: $second-black;
}
