@import 'src/modules/common/styles/utils/variables';

.filterBtn {
  border-radius: 6px;
  background-color: $white;
  color: $legato-branded-primary;
  border-color: $legato-branded-primary;
  height: 20px;
  font-weight: 600;
  font-size: 13px !important;
  i{
    font-size: 11px;
    width: 11px;
    height: 11px;
    margin: 0px -4px 0px 3px;
  }
}

.filterBtn:hover {
  color: $legato-branded-primary;
  background-color: #e6ebf6;
  border-color: $legato-branded-primary;
  box-shadow:3px 3px 6px rgba(0,0,0,.2);
}

.customRenderItem {
  background-color: $lt-blue;
  font-weight: bold;
  color: $accent-primary;
  cursor: default;
}

.clearFilterBtn {
  cursor: pointer;
}

.selectedUser {
  display: flex;
  align-items: center;
  gap: 5px;
}

.selectedUserIcon {
  display: inline-flex;
  align-items: center
}
