@import 'src/modules/common/styles/utils/variables';

.headerWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}
.data {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 200px;
  flex-grow: 1;
}

.item {
  margin-bottom: 20px;
  width: 33.3%;
}

.label {
  margin-bottom: 10px;
  font-weight: 600;
  line-height: 14px;
  color: $second-black;
}

.values {
  line-height: 14px;
  color: $second-black;
}

.idLink {
  color: var(--primary);
}

.clip {
  .clipIcon {
    opacity: 0;
  }

  &:hover {
    cursor: default;
    color: var(--primary);

    .clipIcon {
      opacity: 1;
      cursor: pointer;
    }
  }
}

.button {
  font-weight: 400;
}

.currentlyAssigned {
  margin-left: 15px;
  font-weight: normal;
  color: $success-green;
}

.header {
  display: flex;
}
.start {
  flex-grow: 1;
}

.artistAvatarWrapper {
  display: flex;
  width: 200px;
  margin-right: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.artistAvatar {
  width: 184px;
  height: 184px;
  margin: 20px 0;
  border-radius: 5px;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}

@media (max-width: 778px) {
  .data {
    max-height: 230px;
  }
}
