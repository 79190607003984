.container {
  background: var(--lt-gray-4);
  border-radius: 4px;
  padding: 35px 0;
  max-height: 600px;
  overflow: visible;
  position: static;
  animation: .2s ease-in 0s showHideAdvancedSearchForm;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  font-family: var(--default-font-family, "SuisseIntl");
  margin-bottom: 20px;
}
.form {
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  .advancedSearchRow {
    width: 500px;
    word-break: keep-all;
  }
}
.closeBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.formRow {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  .label {
    flex-basis: 120px;
    flex-shrink: 0;
  }

  .controls {
    flex-grow: 1;
    display: flex
  }

  .control {
    margin: 0;
    flex-grow: 1;
    flex-basis: 60%;

    &.small {
      flex-basis: 30%;
      flex-shrink: 0;
    }
  }

  & + & {
    margin-top: 20px;
  }
}

.actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  margin-left: 300px;
  width: 171px;
  height: 36px;
}
.customControls {
  max-width: 356px;
  width: 100%;
}

.formContent {
  justify-content: center;

  .firstContentColumn {
    margin-right: 108px;
  }


}
.selectFieldsWithInputs {
  > div:first-child {
    flex-basis: 35%;
  }
  > div:last-child {
    flex-grow: 1;
  }

  *[class^="DatePicker"] {
    * {
      min-width: 0;
    }
  }
}
.nowrapLabel {
  white-space: nowrap;
  width: 130px;
}

@keyframes showHideAdvancedSearchForm{
  0% {
    overflow: hidden;
    height: 0;
   }
  50% {
    height: 200px
  }
  100% {
    height: 400px
  }
}

.inputButton {
  margin-bottom: 0;
  button{
    line-height: normal;
  }
}
 