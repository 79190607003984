@import '../utils/variables';

.scrim-container__su-procure {
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.isni-procurement-quansic__wrapper {
  display: flex;
  align-items: center;
  flex: auto;

  .form-section {
    margin-top: 20px;
  }

  .isni-procurement-quansic__contents {
    position: relative;
    height: 100%;
    margin-bottom: 40px;

    .tooltip-warning {
      background-color: $warning-solid !important;
    }
    .tooltip-warning.place-top::after {
      border-top-color: $warning-solid !important;
    }
    .submit-wrapper {
      height: 400px;
      display: flex;
      align-items: center;
    }
    form {
      position: relative;
      padding-bottom: 60px;
      display: flex;
      flex-direction: column;
      flex: auto;
    }

    background-color: #fff;
    overflow-y: auto;
    max-height: 85vh;
    width: 620px;
    flex: auto;
    .start-date-group {
      position: relative;
      .fa-asterisk {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-20px, -50%);
        color: #0061ab;
        font-size: 11px !important;
      }
    }
    .error-container {
      padding: 10px 10px;
      margin-bottom: 0;
      border-bottom: none;
      border-left: 3px solid #e8be04;
      background-color: #fbf3e3;
      h3 {
        color: #e8be04;
      }
      p {
        color: #000;
        &:not(:last-of-type) {
          margin-bottom: 0;
        }
      }
    }

    .label-group {
      i {
        color: #71767c;
        font-size: 11px !important;
        margin-top: 10px;
        margin-bottom: 5px;
        margin-left: 5px;
      }
      .fa-asterisk {
        color: #0061ab;
      }
      .fa-exclamation-triangle {
        color: $warning-solid;
      }
    }
    .form-status {
      position: relative;
      display: flex;
      justify-content: space-evenly;
    }
    .status-pair {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      word-wrap: none;
      white-space: nowrap;
      margin-bottom: 25px;
      .number-circle {
        background-color: #fff;
        border: 2px solid #0061ab;
        color: #0061ab;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        p {
          margin: 0;
        }
      }
      .status-label {
        position: absolute;
        top: 0;
        margin-top: 25px;
      }
      .number-circle__highlighted {
        background-color: #0061ab;
        color: #fff;
      }
    }

    .form-section__contributions {
      margin-top: 20px;
    }

    h4 {
      font-size: 18px;
      margin-bottom: 0;
    }

    button {
      i {
        font-size: 13px !important;
        margin-left: 5px;
      }
    }
    .btn-add-contribution {
      color: #0061ab;
      transition: color 0.2s ease;
      &:hover {
        color: $accent-link;
      }
    }
    .none-provided {
      color: #c0c4cc;
    }
    textarea {
      resize: none;
    }

    input,
    select {
      width: 100%;
      height: 30px;
    }
    input,
    select,
    textarea {
      font-size: 13px !important;
      border: 1px solid #c1c4cc;
    }
    input,
    textarea {
      color: #000;
    }

    .select__active-value {
      color: #000;
    }

    label {
      margin-top: 10px;
    }
    .label-group {
      display: flex;
      align-items: center;
    }
    .label-val-group {
      display: flex;
      flex-direction: column;
    }

    .btn-remove-contribution {
      display: flex;
      justify-content: flex-end;
    }
    .bottom-btns {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      width: 100%;
      padding-bottom: 10px;
      &__right {
        display: flex;
        margin-right: 21px;
      }
      &__left {
        margin-left: 21px;
      }
    }
    .collapsible-group {
      .collapsible-group__toggle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        border-bottom: 1px solid #c1c4cc;
        &:hover {
          h4,
          i {
            color: $accent-primary;
          }
        }
      }
    }
    .warning {
      margin-top: 25px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      i {
        color: #71767c;
        font-size: 16px;
        margin-right: 10px;
      }
      p {
        font-size: 13px;
        color: #000;
        margin-bottom: 0;
      }
    }
    .error-container {
      margin: -20px;
      margin-bottom: 20px;
    }
    .error-field {
      border: 1px solid $warning-solid;
      color: $warning-solid;
      &::placeholder {
        color: $warning-solid;
      }
    }
    .error-text {
      p,
      i {
        color: $warning-solid;
      }
    }

    .message {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-left: 5%;
      padding-right: 5%;
      width: 100%;

      i {
        position: absolute;
        top: 0;
        transform: translateY(-20px);
      }
      .spinner {
        position: absolute;
        top: 0;
        transform: translateY(-60px);
      }
      h3,
      i {
        font-size: 26px !important;
      }
      h3 {
        text-align: center;
      }
      &__loading {
        h3,
        i {
          color: #e8be04;
        }
      }
      &__warning {
        h3,
        i {
          color: #e8be04;
        }
      }
      &__success {
        h3,
        i {
          color: #008642;
        }
      }

      &__error {
        h3,
        i {
          color: $warning-solid;
        }
      }
    }
  }
}

@media (max-width: 920px) {
  .isni-procurement-quansic__wrapper {
    .well {
      height: auto;
    }
  }
}

@media (max-width: 800px) {
  .isni-procurement-quansic__wrapper {
    width: 100vw;
    align-items: flex-start;
  }
}
