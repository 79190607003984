.header {
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  color: var(--lt-black);
  margin: 0;
  padding: 0;
}

.btnGroup {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  text-align: center;
  margin-top: 40px;
}

.item {
  flex: 1 1 50%;
  padding: 0 5px;
}

.caption {
  font-size: 13px;
  color: #646464;
  line-height: 14px;
  margin-top: 17px;
  padding: 0 10px;
}

.icon {
  margin-right: 10px;
  position: relative;
  & svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.btn {
  composes: btn from '/src/@grow-components/Button/Button.module.scss';
  composes: btn_large from '/src/@grow-components/Button/Button.module.scss';
  composes: btn_info from '/src/@grow-components/Button/Button.module.scss';
  composes: btn_solid from '/src/@grow-components/Button/Button.module.scss';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 6px;
  font-weight: 600;
  font-family: var(--default-font-family, "SuisseIntl");
  line-height: 16px;
}
