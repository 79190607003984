// Position tip at center
#data-from-gcdm-tip {
  &::before,
  &::after {
    left: 85%;
  }
}

#audit-history-tooltip {
  p {
    &:not(:last-of-type) {
      border-bottom: 1px solid white;
      padding-bottom: 5px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

#audit-history-tooltip,
#audit-history-tooltip-none-found,
#audit-history-tooltip-loading {
  p {
    font-size: 13px;
  }
}
