.select {
  background-color: #fff;
  padding: 5px 6px;
}

.placeholder {
  color: #c1c4cc;
  font-style: italic;

  option {
    color: #000;
    font-style: normal;
  }
}
.wrapperIcon {
  position: relative;
  .customIcon {
    position: absolute;
    top: calc(50% - 7px);
    right: 12px;
    pointer-events: none;
    color: #0032A0;
  }
}
.chevronHidden {
  appearance: none;
  padding: 5px 10px;
}

.overrideStyles {
  //this to avoid the dynamic height in the legato select
  max-height: calc(50vh - 60px)!important;
  button {
    // remove conflict with the x-mark icon from legato
    line-height: normal;
  }
}