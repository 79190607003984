.default-button {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  appearance: none;
  display: block;
}
.button {
  composes: default-button;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 0;
  &::after {
    content: '';
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-bottom-color: currentColor;
    position: absolute;
    top: 0;
    left: 0;
  }
  &.desc {
    top: initial;
    bottom: 0;
    &::after {
      transform: rotate(180deg);
      bottom: 0;
      top: auto;
    }
  }
}
.btnGroup {
  margin-left: 5px;
  &.active .button.active {
    display: none;
  }
}
.col {
  display: flex;
  align-items: center;
  position: relative;
}
.label {
  composes: default-button;
}
