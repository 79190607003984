.container {
  font-size: 13px;
  line-height: 18px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}


.section {
  & + & {
    margin-top: 20px;
  }
}

.sectionTitle {
  display: flex;
  gap: 10px;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 15px;
}

.sectionContent {
}

.paramsRow {
  display: flex;
  gap: 10px;

  & + & {
    margin-top: 25px;
  }

  & > div {
    flex: 1;
  }
}

.paramsItem {
}

.paramsLabel {
  display: flex;
  align-items: center;
  gap: 5px;
}

.warning {
  color: #D9A81B
}

.success {
  color: #008642
}

// TODO REMOVE AFTER BUTTON REFACTOR
.cancelButton {
  composes: btn from '/src/@grow-components/Button/Button.module.scss';
  composes: btn_info from '/src/@grow-components/Button/Button.module.scss';
  composes: btn_solid from '/src/@grow-components/Button/Button.module.scss';
}
