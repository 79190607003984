@import '../../styles/utils/variables';
@import 'src/@grow-components/Form/StyledSelect/StyledSelect.module.scss';

%hover-select {
  border: 1px solid $md-gray;
  color: black;
  text-decoration: none;

  .arrow-append {
    background: $md-gray;
  }
}

.btn {
  &-isni-link {
    display: flex;
    align-items: center;
    padding: 0;

    &:hover, &:focus {
      text-decoration: none;
    }

    .arrow-append {
      height: unset  !important;
      line-height: 0;
      i {
        color: $accent-primary;
        margin: 0 !important;
      }
    }
  }

  .toast-name {
    font-weight: 700;
    text-decoration: 'underline';
  }

  &-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    border: 1px solid #c5c5c5;
    color: black;
    padding-left: 16px;
    height: 35px;
    cursor: text;
    &--open {
      border-bottom-color: black !important;
    }

    &:hover {
      @extend %hover-select;
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
    }
    &:focus {
      @extend %hover-select;
    }
  }
  &--icon {
    @extend .icon;
    top: 22px;
    right: 14px;
    position: static;
    &--open {
      transform: rotate(-180deg);
      transition: 0.3s transform ease-in-out;
      pointer-events: none;
    }
  }

  .arrow-append {
    height: 30px;

    i {
      color: $lt-black-3;
      margin: 10px 4px;
    }
  }
}

.label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  padding: 0;
  font-family: var(--default-font-family, "SuisseIntl");
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: $black;
}

.readMode {
  display: flex;
  flex-direction: column;
}