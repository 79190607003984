@import 'src/modules/common/styles/utils/variables';

.checkbox {
  display: inline-flex;
  align-items: center;
  position: relative;

  label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin: 0;

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    margin-left: 5px;
    font-size: 13px;
    color: $second-black;
    font-family: var(--default-font-family, "SuisseIntl");
  }
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  appearance: none;
  &:focus {
    & ~ .empty {
      outline: 1px solid var(--accent-primary);
    }
    & ~ .full {
      outline: 1px solid var(--accent-primary);
    }
  }

  & ~ .full {
    display: none;
  }

  &:checked {
    & ~ .full {
      display: inline-block;
    }

    & ~ .empty {
      display: none;
    }
  }
}
