@import '../utils/variables';
.party-page {
  width: 100%;

  &__spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
  h4 {

  }
  h3 {
    color: #000;
  }
  .module {
    border-bottom: 1px solid $md-gray-2;
    &:first-of-type:not(.party-form__header) {
    }

    &__header {
      display: flex;
      align-items: center;
    }
  }

  label {
    margin: 0;
    color: $legato-branded-primary;
    font-family: var(--default-font-family, "SuisseIntl");
    font-weight: 600;
    line-height: 18px;
  }

  .name-value {
    font-size: 16px;
  }
  .form-group {
    display: flex;
    flex-direction: column;
  }
  .no-data-available {
    color: $second-black;
  }
  .dod,
  .dob {
    p {
      margin-bottom: 17.5px;
    }
  }
  .artist-image {
    display: flex;
    border-radius: 6px;
    justify-content: center;
    overflow: hidden;
    width: 217px;
    height: 217px;

    .img-preview, .img-placeholder {
      object-fit: cover;
      object-position: top;
      width: 217px;
      height: 217px;
    }
  }
  .legal-name-label {
    margin-left: 10px;
  }
  .privacy-indicator {
    color: var(--accent-link);
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
  .dotted-form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 10px 10px 10px;
    .dots {
      border-top: 1px dotted #71767d;
      border-left: 1px solid #fff;
      height: 1px;
      width: 100%;
      margin-left: 5px;
    }
  }
  .translations,
  .competencies {
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
    grid-auto-rows: auto;
    .translation,
    .competency {
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      .translation__language {
        margin-bottom: 3px;
      }
      input {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
  .id-value-container {
    margin-right: 10px;
    white-space: nowrap;
    justify-content: space-between;
    p {
      margin-left: 10px;
      margin-top: 6px;
      margin-bottom: 6px;
    }
    .btn-copy-id {
      margin-left: 10px;
      justify-content: flex-end;
      border: none;
    }
  }
  .btn-add-translation,
  .btn-add-competency {
    font-size: 11px;
    span {
      display: flex;
      align-items: center;
    }
    text-transform: none;
    .fas {
      font-size: 11px !important;
    }
  }
  .competencies__items {
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
    margin-left: 10px !important;
    margin-right: -10px;
    grid-auto-rows: auto;
    .competency {
      padding-right: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
    }
  }
  .remark__items {
    margin-top: 10px;
    .remark {
      box-sizing: border-box;
      padding: 10px;
      border: none;
      .remark-readonly {
        display: flex;
        flex-direction: column;
        .remark-type {
          text-transform: uppercase;
          color: #71767d;
          font-size: 9px;
          margin-bottom: 10px;
        }
        .remark-info {
          white-space: nowrap;
          margin-top: 7px;
          font-size: 11px;
          color: #71767d;
          i {
            margin-left: 3px;
          }
        }
      }
      .remark-form {
        margin-bottom: 0;
      }
      .remark-buttons {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        button {
          margin-right: 5px;
        }
      }
      input,
      textarea {
        height: 120px;
        margin-top: 10px;
        margin-bottom: 10px;
        resize: none;
      }
    }
  }

  &__vertical-centered {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 178px;
  }

  .field-margin {
    margin-top: 40px;
  }

  .signed-pill {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -2px;
    left: -15px;
    background: #0032A0;
    font-size: 9px;
    font-weight: 400;
    font-style: normal;
    line-height: 12px;
    border-radius: 6px;
    width: 77px;
    height: 16px;
    color: white;
    margin: 10px 30px;
  }

  .signed-icon {
    width: 11px;
    height: 10px;
    margin: 3px 4px 3px 3px;
  }

  .signed-icon {
    width: 11px;
    height: 10px;
    margin: 3px 4px 3px 3px;
  }

  .signed-text{
    margin: 3px 5px 4px 0;
    height: 10px;
  }

  .signed-text{
    margin: 3px 5px 4px 0;
    height: 10px;
  }
}
