@import '../../modules/common/styles/utils/variables';

th {
  background-color: #f4f4f4;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.sticky {
  align-self: flex-start;
  position: sticky;
  top: 160px;
}

.title {
  font-family: 'SuisseIntl';
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  color: #000000;
}

.table {
  margin-bottom: 40px;
  align-self: stretch;
}

.expand {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  color: $legato-branded-primary;
  &:hover {
    cursor: pointer;
  }
}
.icon {
  margin-left: 10px;
  margin-right: 5px;
}
.label {
  font-family: 'SuisseIntl';
  padding: 25px 15px 10px;
  color: #3F3F3F;
  &:nth-child(1) {
    color: $second-black;
  }

}
.cell {
  padding: 18px 15px 15px;
  border-bottom: 2px solid $md-gray-3;
  line-height: 14px;
}

.cellCol {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 600;
  .btnGroup {
    margin-left: 10px;
  }
  span {
    user-select: none;
  }
}
.noSortable {
  cursor: default;
}
.default-button {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  appearance: none;
  display: block;
}
.active {
  display: none;
}
.button {
  composes: default-button;
  width: 8px;
  height: 8px;
  position: absolute;
  top: -2px;
  &::after {
    content: '';
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-bottom-color: currentColor;
    position: absolute;
    top: 0;
    left: 0;
  }
  &.desc {
    top: initial;
    bottom: 0;
    &::after {
      transform: rotate(180deg);
      bottom: 0;
      top: auto;
    }
  }
}