.button {
  background: none;
  border: 0 none;
  width: 30px;
  height: 100%;
}

.buttonGroup {
  display: flex;
  align-items: center;
  position: absolute;
  height: 30px;
  top: 3px;
  bottom: 0;
  right: 5px;
}
