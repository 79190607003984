.container {
  padding: 30px 30px 20px;
  .controls {
    max-width: none;
    min-width: unset;
    width: 100%;
  }
}

.title {
  margin-bottom: 30px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}