.container{
  margin-top: 10px;
  padding: 0 20px 35px;
  display:flex;
  flex-wrap: wrap;
}
.listItem{
  padding: 15px 10px 15px 10px;
}

