@import 'src/modules/common/styles/utils/variables';

.selectContent {
    width: 160px;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

.label {
  font-family: var(--default-font-family, "SuisseIntl") !important;
  line-height: 16px;
  margin-bottom: 5px;
}

.selectBlock {
  margin-bottom: 15px;
}