.container {
  background-color: #fff;
  width: 386px;
  height: fit-content;
  border: 1px solid #CBCBCB;
  border-radius: 8px;
}

.closeButton {

}

.header {
  padding: 30px 30px 10px;
  display: flex;
}

.content {
  padding: 15px 30px
}

.footer {
  padding: 15px 30px;
}

.title {
  font-size: 26px;
  height: 29px;
  flex-grow: 1
}
.closeButton{

}
