.card {
  border: none;
  border-radius: 0;
}

// Trigger theme hover behavior on <p> and <i> elements in dropdown elements
.btn {
  text-transform: none;
}
.btn-dropdown {
  background-color: #fff !important;

  &:hover {
    .btn-dropdown-element {
      color: #0061ab;
    }
  }
}

.btn-uppercase {
  text-transform: uppercase;
}

.btn-isni-link {
  background-color: transparent !important;
  display:flex;

  span{
    margin-right: auto;
  }

  &:disabled {
    border: none;

    .btn-dropdown-element {
      color: #0061ab;
    }
  }
}

.btn-no-bg {
  background-color: transparent;
  color: #0061ab;

  &:hover {
    color: #03345a;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 40px;
}

.col {
  max-width: 100%;
  flex: 1 1 auto;
}

// remove gray borders from some wells
.well-no-border {
  border: none;
}

// center rows
.centered-row {
  display: flex;
  justify-content: center;
}

.w-100 {
  width: 100%;
}

// remove borders from readonly form fields
// (not available in current theme version)
.plaintext {
  border: none;
}


@media (min-width: 1280px) {
  .container {
    width: 1200px;
  }
}

.btn-sm {
  i.fa:only-child {
    margin: 0;
  }
}
.btn-link:disabled{
  border: none;
}
