@import 'src/modules/common/styles/utils/variables';

.item {
    width: 25%;
  }

.label {
    font-weight: bold;
    margin-bottom: 10px;
}

.content {
    padding-bottom: 40px;
}

.idLink {
    color: var(--primary);
    font-weight: 600;
    line-height: 14px;
  }

  .clip {
    line-height: 14px;
    color: $second-black;
    
    .clipIcon {
      opacity: 0;
    }
  
    &:hover {
      cursor: default;
      color: var(--primary);
  
      .clipIcon {
        opacity: 1;
        cursor: pointer;
      }
    }
  }

.identifiersDrawer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #F2F2F2;
    row-gap: 35px;
    padding: 15px 50px 15px 120px;
    align-self: center;
}

.hideButton {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-bottom: 20px;
    color: #004478
}

@media (max-width: 992px) {
  .item {
    width: 33.33%;
  }

}
@media (max-width: 778px) {
  .item {
    width: 50%;
  }
  .identifiersDrawer {
    padding-left: 50px;
  }
}