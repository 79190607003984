.wrapper {
  max-width: 1200px;
  margin: 0 auto 60px auto;
  padding: 0 10px;
}

.container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 2fr 1fr 2fr;
  grid-template-rows: repeat(4, minmax(180px, auto));
  grid-template-areas:
  "item-a item-a item-b"
  "item-c item-d item-d"
  "item-c item-e item-e"
  "item-f item-f item-g";
}


.itemA {
  grid-area: item-a;
}

.itemB {
  grid-area: item-b;
}

.itemC {
  grid-area: item-c;
}

.itemD {
  grid-area: item-d;
}

.itemE {
  grid-area: item-e;
}

.itemF {
  grid-area: item-f;
}

.itemG {
  grid-area: item-g;
}
